import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Container = () => (
  <Layout>
    <SEO title="Home" />
    <div className="home">
      <header
        className="color-bg light-typo padding-top-bottom bg-image detail-header"
        style={{
          backgroundImage: "url(/images/etc/reservation_header.jpg)",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <h1 className="page-title">자주하시는 질문</h1>

          <p className="page-subtitle">
            자주하시는 질문들에 대한 답변을 미리 정리 해 보았어요. 전화문의 전
            참고해주세요.
          </p>
        </div>
      </header>

      <article className="post single-post">
        <div className="container fade-up">
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1">
              <div className="post-content">
                <div
                  className="panel-group"
                  id="accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div className="panel panel-success">
                    <div className="panel-heading" role="tab">
                      <h4 className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#faq_01"
                          aria-expanded="true"
                          aria-controls="faq_01"
                        >
                          <i className="fa fa-question-circle"></i> 입실은
                          몇시부터 가능한가요?
                        </a>
                      </h4>
                    </div>
                    <div
                      id="faq_01"
                      className="panel-collapse collapse"
                      role="tabpanel"
                    >
                      <div className="panel-body">
                        입실시간은 15:00시부터입니다.
                        <br />전 손님이 11시에 퇴실하시기때문에 청소 하는 데
                        시간이 필요하거든요.
                        <br />
                        짐은 그 전에 놓고 가셔도됩니다.^^
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-success">
                    <div className="panel-heading" role="tab">
                      <h4 className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#faq_02"
                          aria-expanded="true"
                          aria-controls="faq_02"
                        >
                          <i className="fa fa-question-circle"></i> 예약이
                          완료되었는지는 어떻게 확인할수 있나요?
                        </a>
                      </h4>
                    </div>
                    <div
                      id="faq_02"
                      className="panel-collapse collapse"
                      role="tabpanel"
                    >
                      <div className="panel-body">
                        예약금을 입금하셔야 예약이 완료됩니다.
                        <br /> 입금은 꼭 예약자 이름으로 해주시고 전화로
                        확인해주세요^^
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-success">
                    <div className="panel-heading" role="tab">
                      <h4 className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#faq_03"
                          aria-expanded="true"
                          aria-controls="faq_03"
                        >
                          <i className="fa fa-question-circle"></i> 바베큐를
                          해먹을 장소가 있나요?
                        </a>
                      </h4>
                    </div>
                    <div
                      id="faq_03"
                      className="panel-collapse collapse"
                      role="tabpanel"
                    >
                      <div className="panel-body">
                        바베큐장이 별도로 마련되어있습니다.^^
                        <br />
                        따뜻한 난로도 있어서 겨울에도 춥지않게 바비큐를 해드실
                        수 있답니다.
                        <br />
                        4인기준 1만원에 이용하실 수 있구요. 숯불,그릴을 제공
                        해드립니다. 고기 등 음식만 사오세요^^
                        <br />
                        <a href="/spaces/barbecue">다누리 바베큐장 알아보기</a>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-success">
                    <div className="panel-heading" role="tab">
                      <h4 className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#faq_04"
                          aria-expanded="true"
                          aria-controls="faq_04"
                        >
                          <i className="fa fa-question-circle"></i> 예약취소시
                          취소수수료는 어떻게 되나요?
                        </a>
                      </h4>
                    </div>
                    <div
                      id="faq_04"
                      className="panel-collapse collapse"
                      role="tabpanel"
                    >
                      <div className="panel-body">
                        <div>
                          <p>
                            <span
                              style={{ fontSize: "14px", color: "#7a7c7f" }}
                            >
                              * 취소수수료는 예약일자가 아닌 이용일 기준입니다.
                            </span>
                            <br />
                            <span
                              style={{ fontSize: "14px", color: "#7a7c7f" }}
                            >
                              * 전 기간 취소수수료는 공제금액이 큰 금액을
                              적용합니다.
                            </span>
                          </p>
                          <table className="table table-brodered">
                            <tbody>
                              <tr className="active">
                                <td>취소 날짜</td>
                                <td>환불 금액</td>
                              </tr>
                              <tr className="">
                                <td>이용당일 </td>
                                <td>환불 불가</td>
                              </tr>
                              <tr className="">
                                <td>1일 전 </td>
                                <td>30% 환불</td>
                              </tr>
                              <tr className="">
                                <td>2일 전 </td>
                                <td>50% 환불</td>
                              </tr>
                              <tr className="">
                                <td>3일 전 </td>
                                <td>70% 환불</td>
                              </tr>
                              <tr className="">
                                <td>4일 전 </td>
                                <td>80% 환불</td>
                              </tr>
                              <tr className="">
                                <td>5일 전 </td>
                                <td>90% 환불</td>
                              </tr>
                              <tr className="">
                                <td>6일 이상 전</td>
                                <td>95% 환불 (송금수수료 포함)</td>
                              </tr>
                              <tr className="">
                                <td>6일 이상 전 (성수기)</td>
                                <td>90% 환불 (송금수수료 포함)</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-success">
                    <div className="panel-heading" role="tab">
                      <h4 className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#faq_05"
                          aria-expanded="true"
                          aria-controls="faq_05"
                        >
                          <i className="fa fa-question-circle"></i> 애완동물을
                          데려올 수 있나요?
                        </a>
                      </h4>
                    </div>
                    <div
                      id="faq_05"
                      className="panel-collapse collapse"
                      role="tabpanel"
                    >
                      <div className="panel-body">
                        위생문제 등의 문제로 다른 손님에게 불편을 끼칠 수 있어
                        애완동물은 데려오실 수 없습니다.
                        <br /> 양해바랍니다.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  </Layout>
)

export default Container
